export class CouponDetails {
  title?: string;
  description?: string;
  code?: string;
  active?: boolean|string;
  auto_apply?: boolean|string;
  start_date?: string;
  end_date?: string;
  is_lifetime
  users?: any = [];
  applicable_to_all_users?: boolean|string;
  regions?: any = [];
  applicable_to_all_regions?: boolean|string;
  categories?: any = [];
  applicable_to_all_categories?: boolean|string;
  applicable_to_all_first_ride?: boolean|string;
  no_of_rides_per_user?: number|string;
  discount_type?:string;
  amount?:string;
  image?:string;
}
export class CouponsParams {
  active?: string;
  date?: string;
  region?: string;
  user?: string;
  category?: string;
  page?: Number;
  limit?: string;
}

export class ConfigSetting {
  driver_action?: boolean;
  rider_action?: boolean;
  vehicles_action?: boolean;
  cab_body_type_action?: boolean;
  cab_amenities_action?: boolean;
  cab_category_action?: boolean;
  rides_action?: boolean;
  region_action?: boolean;
  pincode_action?: boolean;
  pickup_drop_action?: boolean;
  manual_dispatcher_action?: boolean;
  payment_setting_action?: boolean;
  user_payment_action?: boolean;
  withdrawal_action?: boolean;
  referral_setting_action?: boolean;
  referral_code_action?: boolean;
  referral_usage_code_action?: boolean;
  coupon_action?: boolean;
  admin_user_action?: boolean;
  admin_role_action?: boolean;
  app_store_action?: boolean;
  social_links?: boolean;
  helps_driver?: boolean;
  helps_user?: boolean;
  faq_driver?: boolean;
  faq_user?: boolean;
  // edit only
  driver_edit_action?: boolean;
  rider_edit_action?: boolean;
  vehicles_edit_action?: boolean;
  cab_body_type_edit_action?: boolean;
  cab_amenities_edit_action?: boolean;
  cab_category_edit_action?: boolean;
  rides_edit_action?: boolean;
  region_edit_action?: boolean;
  pincode_edit_action?: boolean;
  pickup_drop_edit_action?: boolean;
  manual_dispatcher_edit_action?: boolean;
  payment_setting_edit_action?: boolean;
  user_payment_edit_action?: boolean;
  withdrawal_edit_action?: boolean;
  referral_setting_edit_action?: boolean;
  referral_code_edit_action?: boolean;
  referral_usage_code_edit_action?: boolean;
  coupon_edit_action?: boolean;
  admin_user_edit_action?: boolean;
  admin_role_edit_action?: boolean;
  app_store_edit_action?: boolean;
  social_links_edit?: boolean;
  helps_driver_edit?: boolean;
  helps_user_edit?: boolean;
  faq_driver_edit?: boolean;
  faq_user_edit?: boolean;

}
