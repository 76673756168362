// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://192.168.1.60:301'
  // apiUrl: 'http://dev.lwayonline.com:301',
  apiUrl: 'https://dev.lwayonline.com:311',
  // apiUrl: 'http://192.168.1.65:4201',
  // apiKey: 'AIzaSyCS--tn9p9ATi0Lu9bkTL_jdw8feedWsOE',
  apiKey: 'AIzaSyBBrUPpsnFhxdwUwsNR9d9i8Hoxr1VDTIA',
  apiSocket: 'https://dev.lwayonline.com:8084/',
  // apiSocket: 'http://dev.lwayonline.com:8083/',
  imageUrl: 'https://lway-ssidemo.s3.ap-south-1.amazonaws.com/src'
  // apiSocket: 'http://192.168.1.65:8083/',
};
