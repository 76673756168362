import { Component, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CallRequestService } from './service/call-request.service';
import { AuthService } from 'app/service/auth.service';
import { PushNotificationsService } from 'ng-push';
import { Observable } from 'rxjs';
import { Subscription } from "rxjs/Subscription";
import { Router } from "@angular/router";
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {
    // Set toastr container ref configuration for toastr positioning on screen
    sub = new Subscription();

    constructor(public toastr: ToastrService,
        private callService: CallRequestService,
        private _pushNotifications: PushNotificationsService,
        private authService: AuthService,
        private router: Router,
        private ngZone: NgZone) {
            this._pushNotifications.requestPermission();
    }

    ngOnInit() {
        this.sub = Observable.timer(0, 30000)
        .subscribe((val) => {
            if(this.authService.loggedIn())
            {
                this.getPendingList();
            }
        });
    }

    getPendingList() {
        this.callService.getPendingListPage().subscribe(res => {
            let myAudio = new Audio("../assets/uploads/notify.mp3");
            if(res['docs'] != '')
            {
                let result = res['docs'];
                let options = { //set options
                    body: "You have an incoming call notification from "+result[0].requested_by.full_name,
                    icon: "../assets/uploads/notification_logo.png", //adding an icon,
                    sound: "../assets/uploads/notify.mp3",
                    silent: false

                }
                this._pushNotifications.create('Incoming call request', options).subscribe( //creates a notification
                    res => {
                        myAudio.play();
                        if (res.event.type === 'click') {
                            console.log('hello');
                            myAudio.pause();
                            res.notification.close();
                            this.ngZone.run(() => {
                                this.router.navigate(['admin/call-request/edit/'+result[0]._id]);
                            });
                        }
                    },
                    err => console.log(err)
                );
            }
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}
