import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: 'dashboard', title: 'Dashboard', icon: 'fa fa-home', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },

  {
    path: '', title: 'Users', icon: 'fa fa-users', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      {
        path: '/admin/management/user', title: 'Riders', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/management/driver', title: 'Drivers', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      }
    ]
  },

  {
    path: '/admin/management/vehicle', title: 'Vehicles', icon: 'fa fa-car', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },

  {
    path: '/admin/corporates', title: 'Corporates', icon: 'fa fa-building-o', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },

  {
    path: '', title: 'Cabs', icon: 'fa fa-taxi', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      {
        path: '/admin/bodytype/view', title: 'Body types', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/amenities/view', title: 'Amenities', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/category/view', title: 'Category', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      }
    ]

  },

  {
    path: '/admin/management/rides', title: 'Rides', icon: 'fa fa-history', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },
  {
    path: '/admin/region/view', title: 'Region', icon: 'fa fa-globe', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },
  {
    path: '', title: 'Call Request', icon: 'fa fa-phone', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      {
        path: '/admin/call-request/pending', title: 'Pending', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/call-request/all-request', title: 'My Request', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/call-request/unattended', title: 'Unattended', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      }
    ]
  },
  // {
  //   path: '/admin/dispatcher', title: 'Manual Dispatcher', icon: 'fa fa-paper-plane', class: '', badge: '',
  //   badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: true, submenu: [
  //   ]
  // },
  {
    path: '', title: 'Manual Dispatcher', icon: 'fa fa-paper-plane', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [

      {
        path: '/admin/dispatcher/Normal', title: 'Special Booking', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/dispatcher/Share', title: 'Share', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/dispatcher/Pickup-Drop', title: 'Point to Point', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/dispatcher/Outstation', title: 'Out-station', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/dispatcher/Rental', title: 'Rental', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },
  // {
  //   path: '', title: 'Manual Dispatcher2', icon: 'fa fa-paper-plane', class: 'has-sub',
  //   badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [

  //     {
  //       path: '/admin/dispatcher2/Normal', title: 'Normal Booking', icon: '', class: '',
  //       badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     },
  //     {
  //       path: '/admin/dispatcher2/Share', title: 'Share', icon: '', class: '',
  //       badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     },
  //     {
  //       path: '/admin/dispatcher2/Pickup-Drop', title: 'Pickup/Drop', icon: '', class: '',
  //       badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     },
  //     {
  //       path: '/admin/dispatcher2/Outstation', title: 'Out-station', icon: '', class: '',
  //       badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     },
  //     {
  //       path: '/admin/dispatcher2/Rental', title: 'Rental', icon: '', class: '',
  //       badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     },
  //   ]
  // },
  {
    path: '/admin/rental/view', title: 'Rental', icon: 'fa fa-car', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },
  // {
  //     path: '', title: 'Region', icon: 'fa fa-globe', class: 'has-sub',
  //     badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
  //         {
  //             path: '/admin/region/view', title: 'Region Management', icon: '', class: '',
  //             badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //         },
  //     ]

  // },

  // {
  //     path: '', title: 'Settings', icon: 'fa fa-sitemap', class: 'has-sub',
  //     badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
  //         {
  //             path: '/admin/account/website', title: 'Website Settings', icon: '', class: '',
  //             badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //         },
  //         {
  //             path: '/admin/account/email', title: 'Email Settings', icon: '', class: '',
  //             badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //         },
  //         {
  //             path: '/admin/account/logo', title: 'Logo Settings', icon: '', class: '',
  //             badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //         },
  //         {
  //             path: '/admin/account/favicon', title: 'Favicon Settings', icon: '', class: '',
  //             badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //         },
  //     ]

  // },

  {
    path: '', title: 'Payment', icon: 'fa fa-money', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [

      // {
      //   path: '/admin/account/payment', title: 'Payment Settings', icon: '', class: '',
      //   badge: '', badgeClass: '', isExternalLink: false, submenu: []
      // },

      // {
      //     path: '/admin/commissions/view', title: 'Commissions', icon: '', class: '',
      //     badge: '', badgeClass: '', isExternalLink: false, submenu: []
      // },

      // {
      //   path: '/admin/management/payment', title: 'User Payment', icon: '', class: '',
      //   badge: '', badgeClass: '', isExternalLink: false, submenu: []
      // },

      // {
      //     path: '/admin/management/driverPay', title: 'Driver Payment', icon: '', class: '',
      //     badge: '', badgeClass: '', isExternalLink: false, submenu: []
      // },

      // {
      //     path: '/admin/management/settlementHistory', title: 'Settlement History', icon: '', class: '',
      //     badge: '', badgeClass: '', isExternalLink: false, submenu: []
      // },
      {
        path: '/admin/withdrawal/view/all_request', title: 'Withdrawal Request', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
          path: '/admin/withdrawal/corporate-settlement', title: ' Corporate Settlement', icon: '', class: '',
          badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },

    ]

  },
  {
    path: '', title: 'Referral', icon: 'fa fa-gift', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      {
        path: '/admin/referral/view', title: 'Referral Settings', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/codes-setting/view', title: 'Referral Codes', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/codes-setting/usage-history/usage-history', title: 'Referral Usage History', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]

  },
  {
    path: '/admin/coupon/view', title: 'Coupon', icon: 'fa fa-percent', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },
  {
    path: '', title: 'Admin', icon: 'fa fa-address-book-o', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      {
        path: '/admin/admin-users/view', title: 'Users', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/admin-roles/view', title: 'Roles', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      }
    ]
  },
  {
    path: '/admin/language/view', title: 'Language', icon: 'fa fa-language', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },
  {
    path: '/admin/reports', title: 'Reports', icon: 'fa fa-file-excel-o', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: []
  },
  // {
  //     path: '', title: 'Static Pages', icon: 'fa fa-file-text', class: 'has-sub',
  //     badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
  //         {
  //             path: '/admin/home/view', title: 'Home Page', icon: '', class: '',
  //             badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //         },
  //         {
  //             path: '/admin/home/driver', title: 'Driver Home Page', icon: '', class: '',
  //             badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //         }
  //     ]

  // },

  // {
  //     path: '/admin/banner/view', title: 'Banners', icon: 'fa fa-file-image-o', class: '', badge: '',
  //     badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
  //     ]
  // },

  {
    path: '/admin/account/app', title: 'App Store', icon: 'fa fa-cloud-download', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },

  {
    path: '/admin/account/sociallink', title: 'Social Links', icon: 'fa fa-bullhorn', class: '', badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
    ]
  },

  {
    path: '', title: 'Helps', icon: 'fa fa-info', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      {
        path: '/admin/page/view', title: 'Driver', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/page/user', title: 'User', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      }
    ]

  },
  // {
  //   path: '', title: 'Helps', icon: 'fa fa-address-book-o', class: 'has-sub',
  //   badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
  //     {
  //       path: '/admin/page/view', title: 'Driver', icon: '', class: '',
  //       badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     },
  //     {
  //       path: '/admin/page/user', title: 'User', icon: '', class: '',
  //       badge: '', badgeClass: '', isExternalLink: false, submenu: []
  //     }
  //   ]
  // },

  // {
  //   path: '/admin/faq/view', title: 'Faq', icon: 'fa fa-question-circle', class: '', badge: '',
  //   badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
  //   ]
  // },

  {
    path: '', title: 'Faq', icon: 'fa fa-question-circle', class: 'has-sub',
    badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [
      {
        path: '/admin/faq/view/driver', title: 'Driver', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/admin/faq/view/user', title: 'User', icon: '', class: '',
        badge: '', badgeClass: '', isExternalLink: false, submenu: []
      }
    ]

  },

];
